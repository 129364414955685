import { Classifier } from '@fleet/shared/dto/classifier';
import { BusinessEntityType } from '@fleet/shared';

export const ALLOWED_BUSINESS_ENTITY_ROLES = [
  BusinessEntityType.CARRIER,
  BusinessEntityType.RETAILER,
  BusinessEntityType.CO_TAXATION_GROUP,
];

export enum Classification {
  BUSINESS_ENTITY = 'BUSINESS_ENTITY',
  INVENTORY_CLASS = 'INVENTORY_CLASS',
  LINE = 'LINE',
  ORGANIZATION = 'ORGANIZATION',
  PRODUCT = 'PRODUCT',
  PRODUCT_CATEGORY = 'PRODUCT_CATEGORY',
  SERVICE_CODE = 'SERVICE_CODE',
}

export enum ClassificationGroup {
  CURRENCY = 'CURRENCY',
  COMMISSION_TYPE = 'COMMISSION_TYPE',
  RETAILER_CONTRACT_STATUS = 'RETAILER_CONTRACT_STATUS',
  SALES_CHANNEL = 'SALES_CHANNEL',
}

export interface ClassificationGroupItem {
  id: ClassificationGroupKey;
  classifications: Array<Classifier>;
}

export const CLASSIFICATION_GROUP_IDS = Object.values(ClassificationGroup);

export type ClassificationGroupKey =
  typeof ClassificationGroup[keyof typeof ClassificationGroup];
