import { createReducer } from '@reduxjs/toolkit';
import { getBusinessEntities, getClassificationGroups, } from 'features/classification/classificationActions';
import { type Classifier } from '@fleet/shared/dto/classifier';
import type { BusinessEntity } from '@fleet/shared/dto/businessEntity';
import { Classification, ClassificationGroupKey } from "dto/classification";

type ClassificationState = Record<ClassificationGroupKey, Array<Classifier>> & {
  [Classification.BUSINESS_ENTITY]: Array<BusinessEntity<number>>;
};

const initialState = {} as ClassificationState;

export const classificationReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getClassificationGroups.fulfilled, (state, action) => {
      action.payload.forEach((data) => (state[data.id] = data.classifications));
    })
    .addCase(getBusinessEntities.fulfilled, (state, action) => {
      state.BUSINESS_ENTITY = action.payload;
    });
});
